import React from "react";
import Nav from "../components/Nav";
import useNewBill from "../hooks/useNewBill";
import { Button, Input } from "@nextui-org/react";

function NewBill() {
  const { handleChange, handleSubmit, loading } = useNewBill();
  return (
    <>
      <Nav />

      <main className="flex flex-col items-center mt-12">
        <header className="md:w-1/2">
          <h3 className="font-bold text-2xl">Nueva Factura</h3>
          <p className="text-sm text-gray-500">
            Crea una factura de un solo uso para un cliente
          </p>
        </header>

        <form
          onSubmit={handleSubmit}
          className="md:w-1/2 mt-8 flex flex-col gap-4"
        >
          <Input
            type="text"
            name="description"
            onChange={handleChange}
            placeholder="Descripción de la factura"
            label="Descripción"
            isRequired
          />

          <Input
            type="number"
            name="total"
            onChange={handleChange}
            placeholder="Monto de la factura"
            label="Monto en dólares"
            isRequired
          />

          <Input
            type="text"
            name="code"
            onChange={handleChange}
            placeholder="Dejar en blanco para generar automáticamente"
            label="# de factura"
          />

          <Button
            isLoading={loading}
            className="w-full"
            color="primary"
            type="submit"
          >
            Crear factura
          </Button>
        </form>
      </main>
    </>
  );
}

export default NewBill;
