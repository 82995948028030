import React, { useState } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Input,
  Textarea,
  Tooltip,
} from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import api from "../lib/api";
import { toast } from "sonner";

export default function App({ receivedProduct }) {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [product, setProduct] = useState(receivedProduct);

  const handdleUpdate = async () => {
    const { data } = await api.put(`/products/${product.id}`, product);
    if (data.success) {
      toast.success("Producto actualizado");
    }
  };

  const uploadFilePromise = (file) => {
    return new Promise(async (resolve, reject) => {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const { data } = await api.post("/files", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        resolve(data);
      } catch (error) {
        console.log(error);
        reject(error);
      }
    });
  };

  const uploadFile = async (file, field) => {
    toast.promise(uploadFilePromise(file), {
      loading: "Cargando archivo",
      success: (data) => {
        if (field === "img") {
          try {
            const imgArr = JSON.parse(product.images ? product.images : "[]");
            imgArr.push(data);
            const stringify = JSON.stringify(imgArr);
            setProduct({ ...product, images: stringify });
          } catch (error) {
            console.log(error);
          }
        } else setProduct({ ...product, [field]: data });
        return `Archivo cargado con éxito`;
      },
      error: "Error",
    });
  };

  return (
    <>
      <Tooltip content="Editar">
        <FontAwesomeIcon
          onClick={onOpen}
          className="cursor-pointer"
          icon={faEdit}
        />
      </Tooltip>
      <Modal size="4xl" isOpen={isOpen} onOpenChange={onOpenChange}>
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                <Input
                  placeholder="Nombre"
                  onInput={(e) => {
                    setProduct({ ...product, name: e.target.value });
                  }}
                  defaultValue={product.name}
                />

                <div className="flex">
                  <Input
                    onInput={(e) => {
                      setProduct({ ...product, description: e.target.value });
                    }}
                    placeholder="Descripción"
                    defaultValue={product.description}
                  />

                  <Input
                    onInput={(e) => {
                      setProduct({
                        ...product,
                        shortDescription: e.target.value,
                      });
                    }}
                    placeholder="Descripción corta"
                    defaultValue={product.shortDescription}
                    maxLength={50}
                  />
                </div>
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-wrap">
                  <Input
                    defaultValue={product.price}
                    className="w-fit"
                    type="number"
                    label="Price"
                    placeholder="0.00"
                    labelPlacement="outside"
                    onInput={(e) => {
                      setProduct({ ...product, price: e.target.value });
                    }}
                    startContent={
                      <div className="pointer-events-none flex items-center">
                        <span className="text-default-400 text-small">$</span>
                      </div>
                    }
                  />

                  <Input
                    defaultValue={product.internationalPrice}
                    className="w-fit ml-3"
                    type="number"
                    label="International Price"
                    placeholder="0.00"
                    labelPlacement="outside"
                    onInput={(e) => {
                      setProduct({
                        ...product,
                        internationalPrice: e.target.value,
                      });
                    }}
                    startContent={
                      <div className="pointer-events-none flex items-center">
                        <span className="text-default-400 text-small">$</span>
                      </div>
                    }
                  />

                  <Input
                    defaultValue={product.discount}
                    className="w-fit ml-3"
                    type="number"
                    label="Decuento en %"
                    placeholder="0"
                    labelPlacement="outside"
                    onInput={(e) => {
                      setProduct({
                        ...product,
                        discount: e.target.value,
                      });
                    }}
                    startContent={
                      <div className="pointer-events-none flex items-center">
                        <span className="text-default-400 text-small">%</span>
                      </div>
                    }
                  />

                  <div className="flex gap-4 my-4">
                    <div className="flex gap-2 items-center">
                      <input
                        type="checkbox"
                        checked={product.active}
                        onChange={(e) => {
                          setProduct({ ...product, active: e.target.checked });
                        }}
                        className="ml-2 white"
                        color="success"
                        id="activeCbx"
                      />
                      <label htmlFor="activeCbx">Activo</label>
                    </div>

                    <div className="flex gap-2 items-center">
                      <input
                        type="checkbox"
                        checked={product.onlyCR}
                        onChange={(e) => {
                          setProduct({ ...product, onlyCR: e.target.checked });
                        }}
                        className="ml-2 white"
                        color="success"
                        id="crCbx"
                      />
                      <label htmlFor="crCbx">Solo Costa Rica</label>
                    </div>
                  </div>

                  <Textarea
                    onInput={(e) => {
                      setProduct({ ...product, details: e.target.value });
                    }}
                    defaultValue={product.details}
                    placeholder="Ingresa detalles del producto separados por ,"
                  ></Textarea>

                  <div className="flex  justify-center">
                    <div class="grid w-full max-w-xs items-center gap-1.5">
                      <label class="text-sm text-gray-400 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Ficha técnica
                      </label>
                      <input
                        onInput={async (e) => {
                          const file = e.target.files[0];
                          await uploadFile(file, "technicalSheet");
                        }}
                        accept="application/pdf"
                        id="picture"
                        type="file"
                        class="flex h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
                      />

                      {product.technicalSheet && (
                        <Button
                          color="success"
                          variant="light"
                          onPress={() =>
                            window.open(
                              `${process.env.REACT_APP_API}/files/` +
                                product.technicalSheet,
                              "_blank"
                            )
                          }
                        >
                          Ver ficha técnica
                        </Button>
                      )}
                    </div>

                    <div class="grid w-full max-w-xs items-center gap-1.5">
                      <label class="text-sm text-gray-400 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Manual
                      </label>
                      <input
                        onInput={async (e) => {
                          const file = e.target.files[0];
                          await uploadFile(file, "manual");
                        }}
                        id="picture"
                        type="file"
                        class="flex h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
                      />

                      {product.manual && (
                        <Button
                          color="success"
                          variant="light"
                          onPress={() =>
                            window.open(
                              `${process.env.REACT_APP_API}/files/` +
                                product.manual,
                              "_blank"
                            )
                          }
                        >
                          Ver manual
                        </Button>
                      )}
                    </div>

                    <div class="grid w-full max-w-xs items-center gap-1.5">
                      <label class="text-sm text-gray-400 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                        Pinout
                      </label>
                      <input
                        onInput={async (e) => {
                          const file = e.target.files[0];
                          await uploadFile(file, "pinout");
                        }}
                        id="picture"
                        type="file"
                        class="flex h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
                        accept="jpg png jpeg webp svg"
                      />

                      {product.pinout && (
                        <Button
                          color="success"
                          variant="light"
                          onPress={() =>
                            window.open(
                              `${process.env.REACT_APP_API}/files/` +
                                product.pinout,
                              "_blank"
                            )
                          }
                        >
                          Ver pinout
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className="mt-4 flex flex-wrap gap-4">
                    {JSON.parse(product.images ? product.images : "[]").map(
                      (img) => (
                        <img
                          onClick={() =>
                            setProduct({
                              ...product,
                              images: JSON.stringify(
                                JSON.parse(product.images).filter(
                                  (image) => image !== img
                                )
                              ),
                            })
                          }
                          className="w-[60px] h-[60px] object-cover rounded-md cursor-pointer"
                          src={`${process.env.REACT_APP_API}/files/${img}`}
                          alt=""
                        />
                      )
                    )}
                    <input
                      onInput={async (e) => {
                        const file = e.target.files[0];
                        await uploadFile(file, "img");
                      }}
                      accept="jpg png jpeg webp svg"
                      id="picture"
                      type="file"
                      class="flex h-10 w-full rounded-md border border-input bg-white px-3 py-2 text-sm text-gray-400 file:border-0 file:bg-transparent file:text-gray-600 file:text-sm file:font-medium"
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button color="danger" variant="light" onPress={onClose}>
                  Close
                </Button>
                <Button color="success" onPress={handdleUpdate}>
                  Guardar cambios
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
}
