import React, { useEffect, useState } from "react";
import {
  Table,
  TableHeader,
  TableColumn,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Tooltip,
} from "@nextui-org/react";
import ProductView from "./ProductEdit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import api from "../lib/api";
import { toast } from "sonner";

export default function App({ receivedProducts }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setProducts(receivedProducts);
  }, [receivedProducts]);

  function handdleDelete(id) {
    api
      .delete(`/products/${id}`)
      .then(() => {
        toast.success("Producto eliminado");
        setProducts(products.filter((product) => product.id !== id));
      })
      .catch((err) => toast.error("Error al eliminar el producto"));
  }

  return (
    <Table aria-label="Products table">
      <TableHeader>
        <TableColumn>Nombre</TableColumn>
        <TableColumn>Precio Nacional</TableColumn>
        <TableColumn>Precio Internacional</TableColumn>
        <TableColumn>Descuento</TableColumn>
        <TableColumn>Solo CR</TableColumn>
        <TableColumn>Estado</TableColumn>
        <TableColumn>Acciones</TableColumn>
      </TableHeader>
      <TableBody emptyContent={"No hay productos"}>
        {products.map((product) => (
          <TableRow key={product.id}>
            <TableCell>{product.name}</TableCell>
            <TableCell>$ {product.price}</TableCell>
            <TableCell>$ {product.internationalPrice || product.price}</TableCell>
            <TableCell>{product.discount} %</TableCell>
            <TableCell>
              <Chip
                className="rounded-full h-[20px] w-[20px]"
                color={product.onlyCR ? "success" : "danger"}
              />
            </TableCell>
            <TableCell>
              <Chip
                className="rounded-full h-[20px] w-[20px]"
                color={product.active ? "success" : "danger"}
              />
            </TableCell>
            <TableCell className="flex gap-4">
              <ProductView receivedProduct={product} />
              <Tooltip content="Eliminar">
                <FontAwesomeIcon
                  onClick={() => handdleDelete(product.id)}
                  className="cursor-pointer mainColor"
                  icon={faTrash}
                />
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
